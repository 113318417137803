<div [ngClass]="menuActive ? 'active' : ''">
  <span
    id="menuButton"
    class="dropdown-toggle ms-2 ms-sm-0"
    data-toggle="dropdown"
    (click)="openMenu()"
    placement="bottom"
    [ngbTooltip]="'MENU' | translate"
    *ngIf="showDealerItems || mobile"
  >
    <i id="menuButtonIcon" class="material-icons">
      {{ !menuActive ? "menu" : "close" }} </i
    ><span class="glyphicon glyphicon-chevron-down pull-right"></span>
  </span>

  <app-mobile-navigation
    *ngIf="menuActive && deviceDetector.isMobile()"
    [browseGroups]="browseGroups"
    [menuItems]="generalItems"
    [settingsItems]="settingsItems"
    (gotoItemEvent)="menuActive = false; gotoItem($event)"
    (closeMenuEvent)="menuActive = false"
  ></app-mobile-navigation>

  <!-- Container overlay -->
  <ul
    *ngIf="!deviceDetector.isMobile()"
    (clickOutside)="closeMenu($event)"
    id="userBar-parent"
    class="py-0 border-0 dropdown-menu mega-dropdown-menu row start-0 userBar-parent position-absolute"
    [ngClass]="[
      menuHovered ? 'hovered' : '',
      menuActive ? 'active' : 'inActive'
    ]"
  >
    <li
      class="px-0 mx-0 col-sm-12 row userBar dark"
      id="userBar-menu"
      [ngClass]="menuActive ? 'active' : 'inActive'"
    >
      <!-- Menu bar -->
      <div
        class="col-12"
        (mouseenter)="menuHovered = true"
        (mouseleave)="menuHovered = false"
      >
        <div
          ngbDropdown
          *ngIf="browseGroups && browseGroups.length"
          class="dropdownContainer d-none d-sm-inline-block browse-groups"
          (mouseenter)="showCatalogHover()"
          (click)="toggleMenuItem(showCatalog, $event)"
          (clickOutside)="showCatalog['active'] = false"
          (mouseleave)="hideCatalogHover()"
        >
          <button
            class="py-2 ps-0 btn generalItem topItem dropdown-toggle"
            id="generalItemCatalog"
          >
            {{ "CATALOG" | translate }}
          </button>
          <div
            ngbDropdownMenu
            [ngClass]="showCatalog['active'] ? 'show' : ''"
            class="mt-0"
          >
            <app-catalogue-dropdown
              *ngIf="testC"
              [browseGroups]="browseGroups"
              (groupSelected)="groupSelectedFromNavbar($event)"
              (hide)="showCatalog['active'] = false"
              [showBom]="
                companyGroupsSettings &&
                companyGroupsSettings.bom_companies &&
                companyGroupsSettings.bom_companies.length
              "
            ></app-catalogue-dropdown>
            <ng-container *ngIf="!testC">
              <ng-container *ngFor="let articleGroup of browseGroups">
                <button
                  class="p-0 dropdown-item"
                  [ngClass]="articleGroup.sub_items ? 'withSubmenu' : ''"
                >
                  <!-- Dropdown in dropdown-->
                  <button
                    class="p-0 btn generalItem"
                    [id]="articleGroup.name"
                    *ngIf="
                      articleGroup.sub_items && articleGroup.sub_items.length
                    "
                  >
                    <span
                      class="w-100 d-block dropdown-toggle"
                      style="padding: 0.375rem 0.75rem"
                      (click)="selectGroup($event, articleGroup.name)"
                    >
                      <span class="d-inline-block" style="width: 95%">{{
                        articleGroup.description
                      }}</span>
                    </span>
                    <div
                      ngbDropdown
                      placement="right"
                      style="right: 0"
                      class="d-inline-block position-absolute"
                      *ngIf="articleGroup.sub_items"
                    >
                      <div
                        ngbDropdownMenu
                        *ngIf="
                          articleGroup.sub_items &&
                          articleGroup.sub_items.length
                        "
                        [ngClass]="
                          articleGroup.sub_items.length > 15 ? 'xl-menu' : ''
                        "
                      >
                        <div
                          [ngClass]="
                            articleGroup.sub_items.length > 15 ? 'row mx-0' : ''
                          "
                        >
                          <ng-container
                            *ngFor="let productGroup of articleGroup.sub_items"
                          >
                            <button
                              *ngIf="!productGroup.sub_items"
                              class="px-3 py-1 dropdown-item w-100"
                              [ngClass]="
                                articleGroup.sub_items.length > 15
                                  ? 'col-6'
                                  : ' w-100'
                              "
                              (click)="
                                selectGroup(
                                  $event,
                                  articleGroup.name,
                                  productGroup.name
                                )
                              "
                            >
                              <span>{{ productGroup.description | translate }}</span>
                            </button>

                            <div
                              *ngIf="productGroup.sub_items"
                              [ngClass]="
                                articleGroup.sub_items.length > 15
                                  ? 'col-6'
                                  : ''
                              "
                              class="px-0"
                            >
                              <button
                                *ngIf="productGroup.sub_items"
                                ngbDropdownToggle
                                class="px-3 text-sub dropdown-item position-relative w-100 d-inline-block"
                                (mouseenter)="productGroup.active = true"
                                (mouseleave)="productGroup.active = false"
                              >
                                <span
                                  class="position-absolute w-100 h-100"
                                  style="top: 0; left: 0"
                                  (click)="
                                    selectGroup(
                                      $event,
                                      articleGroup.name,
                                      productGroup.name
                                    )
                                  "
                                ></span>
                                <span
                                  class="my-2"
                                  (click)="
                                    selectGroup(
                                      $event,
                                      articleGroup.name,
                                      productGroup.name
                                    )
                                  "
                                  class="d-inline-block"
                                  style="width: 95%"
                                  >{{ productGroup.description | translate }}
                                </span>

                                <div
                                  ngbDropdown
                                  placement="right"
                                  class="d-inline-block position-absolute subGroups deepest"
                                  style="right: 0"
                                  *ngIf="productGroup.sub_items"
                                >
                                  <div
                                    ngbDropdownMenu
                                    *ngIf="
                                      productGroup.active &&
                                      productGroup.sub_items
                                    "
                                    [ngClass]="
                                      productGroup.sub_items.length > 15
                                        ? 'xl-menu'
                                        : ''
                                    "
                                  >
                                    <div
                                      class="d-flex flex-column"
                                      [ngClass]="
                                        productGroup.sub_items.length > 15
                                          ? 'row mx-0'
                                          : ''
                                      "
                                    >
                                      <ng-container
                                        *ngFor="
                                          let subgroupItem of productGroup.sub_items
                                        "
                                      >
                                        <button
                                          *ngIf="!subgroupItem.sub_items"
                                          class="dropdown-item w-100"
                                          [ngClass]="
                                            productGroup.sub_items.length > 15
                                              ? 'col-6'
                                              : ' w-100'
                                          "
                                          (click)="
                                            selectGroup(
                                              $event,
                                              articleGroup.name,
                                              productGroup.name,
                                              subgroupItem.name
                                            )
                                          "
                                        >
                                          {{ subgroupItem.description | translate }}
                                        </button>
                                        <button
                                          *ngIf="subgroupItem.sub_items"
                                          ngbDropdownToggle
                                          class="px-3 text-sub dropdown-item position-relative w-100 d-inline-block"
                                          (mouseenter)="
                                            subgroupItem.active = true
                                          "
                                          (mouseleave)="
                                            subgroupItem.active = false
                                          "
                                        >
                                          <span
                                            class="position-absolute w-100 h-100"
                                            style="top: 0; left: 0"
                                            (click)="
                                              selectGroup(
                                                $event,
                                                articleGroup.name,
                                                productGroup.name,
                                                subgroupItem.name
                                              )
                                            "
                                          ></span>
                                          <span
                                            class="my-2"
                                            (click)="
                                              selectGroup(
                                                $event,
                                                articleGroup.name,
                                                productGroup.name,
                                                subgroupItem.name
                                              )
                                            "
                                            class="d-inline-block"
                                            style="width: 95%"
                                            >{{ subgroupItem.description | translate }}
                                          </span>
                                          <div
                                            ngbDropdown
                                            placement="right"
                                            class="d-inline-block position-absolute subGroups deepest"
                                            style="right: 0"
                                            *ngIf="subgroupItem.sub_items"
                                          >
                                            <div
                                              ngbDropdownMenu
                                              *ngIf="
                                                subgroupItem.active &&
                                                subgroupItem.sub_items
                                              "
                                            >
                                              <ng-container
                                                *ngFor="
                                                  let subSubgroupItem of subgroupItem.sub_items
                                                "
                                              >
                                                <button
                                                  *ngIf="
                                                    !subSubgroupItem.sub_items
                                                  "
                                                  class="dropdown-item w-100"
                                                  [ngClass]="
                                                    productGroup.sub_items
                                                      .length > 15
                                                      ? 'col-6'
                                                      : ' w-100'
                                                  "
                                                  (click)="
                                                    selectGroup(
                                                      $event,
                                                      articleGroup.name,
                                                      productGroup.name,
                                                      subgroupItem.name,
                                                      subSubgroupItem.name
                                                    )
                                                  "
                                                >
                                                  {{
                                                    subSubgroupItem.description
                                                  }}
                                                </button>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </button>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </ng-container>
                          <button
                            class="px-3 py-1 dropdown-item btn fw-bold w-100 d-flex align-items-center"
                            (click)="selectGroup($event, articleGroup.name)"
                          >
                            <i class="material-icons">chevron_right</i>
                            {{ "VIEW_ALL" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </button>

                  <!-- Normal item -->
                  <button
                    class="py-2 btn generalItem"
                    [id]="articleGroup.name"
                    *ngIf="!articleGroup.sub_items"
                    (click)="currentLeftItem = articleGroup.name; gotoSearch()"
                  >
                    {{ articleGroup.description | translate }}
                  </button>
                </button>
              </ng-container>

              <button
                class="p-0 dropdown-item withSubmenu"
                *ngIf="
                  showDealerItems &&
                  companyGroupsSettings &&
                  companyGroupsSettings.promotion_companies &&
                  companyGroupsSettings.promotion_companies.length &&
                  ['GHO', 'WG'].includes(companyGroupCode)
                "
                (mouseenter)="getPromotions()"
              >
                <button class="p-0 btn generalItem">
                  <span
                    class="w-100 d-block dropdown-toggle"
                    style="padding: 0.375rem 0.75rem"
                  >
                    <span
                      class="d-inline-block"
                      style="width: 95%; min-width: 200px"
                    >
                      <ng-container
                        *ngIf="!['WG', 'GHO'].includes(companyGroupCode)"
                      >
                        {{ "PROMOTIONS" | translate }}</ng-container
                      >
                      <ng-container
                        *ngIf="['WG', 'GHO'].includes(companyGroupCode)"
                      >
                        {{ "PROMOTIONS_BIKES" | translate }}</ng-container
                      >
                    </span>
                  </span>
                  <div
                    ngbDropdown
                    placement="right"
                    style="right: 0"
                    class="d-inline-block position-absolute"
                  >
                    <div ngbDropdownMenu>
                      <button
                        class="px-3 py-1 dropdown-item btn"
                        *ngIf="['WG'].includes(companyGroupCode)"
                        (click)="
                          gotoSearchPage(
                            promotionPageUrl[companyGroupCode + '_BIKES'],
                            $event
                          )
                        "
                      >
                        {{ "PROMOTIONS_BIKES" | translate }}
                      </button>
                      <button
                        class="px-3 py-1 dropdown-item btn"
                        *ngIf="['GHO'].includes(companyGroupCode)"
                        (click)="
                          gotoSearchPage(
                            'CMN_PROM&HOMEPROMO=ACCOF2&COMPANY=25',
                            $event
                          )
                        "
                      >
                        {{ "OFFERS" | translate }} | GHOST
                      </button>
                      <button
                        class="px-3 py-1 dropdown-item btn"
                        *ngIf="['GHO'].includes(companyGroupCode)"
                        (click)="
                          gotoSearchPage(
                            'CMN_PROM&HOMEPROMO=ACCOF3&COMPANY=25',
                            $event
                          )
                        "
                      >
                        {{ "OFFERS" | translate }} | LAPIERRE
                      </button>
                    </div>
                  </div>
                </button>
              </button>

              <button
                class="p-0 dropdown-item withSubmenu"
                *ngIf="
                  showDealerItems &&
                  companyGroupsSettings &&
                  companyGroupsSettings.promotion_companies &&
                  companyGroupsSettings.promotion_companies.length &&
                  companyGroupCode === 'GHO'
                "
              >
                <button
                  class="p-0 btn generalItem"
                  (click)="
                    gotoSearchPage('CMN_PROM&HOMEPROMO=ACCOF1&COMPANY=25')
                  "
                >
                  <span class="w-100 d-block" style="padding: 0.375rem 0.75rem">
                    <span
                      class="d-inline-block"
                      style="width: 95%; min-width: 200px"
                      >{{ "PROMOTIONS" | translate }} | Gear and Apparel
                    </span>
                  </span>
                </button>
              </button>

              <button
                class="p-0 dropdown-item withSubmenu"
                *ngIf="companyGroupCode == 'COM'"
                (click)="showCatalog['active'] = false"
                [routerLink]="
                  companyGroupCode +
                  '/companyPage/' +
                  this.promotionPageId[companyGroupCode]
                "
              >
                <span
                  class="w-100 d-block xdropdown-toggle"
                  style="padding: 0.375rem 0.75rem"
                >
                  {{ "PROMOTIONS" | translate }}
                </span>
              </button>

              <button
                class="p-0 dropdown-item withSubmenu"
                *ngIf="
                  showDealerItems &&
                  companyGroupsSettings &&
                  companyGroupsSettings.promotion_companies &&
                  companyGroupsSettings.promotion_companies.length &&
                  companyGroupCode !== 'RAL' &&
                  companyGroupCode !== 'COM' &&
                  companyGroupCode !== 'GHO'
                "
                (mouseenter)="getPromotions()"
              >
                <button class="p-0 btn generalItem">
                  <span
                    class="w-100 d-block dropdown-toggle"
                    style="padding: 0.375rem 0.75rem"
                  >
                    <span
                      class="d-inline-block"
                      style="width: 95%; min-width: 200px"
                    >
                      <ng-container *ngIf="companyGroupCode !== 'WG'">
                        {{ "PROMOTIONS" | translate }}</ng-container
                      >
                      <ng-container *ngIf="companyGroupCode === 'WG'">
                        {{ "PROMOTIONS_PARTS" | translate }}</ng-container
                      >
                    </span>
                  </span>
                  <div
                    ngbDropdown
                    placement="right"
                    style="right: 0"
                    class="d-inline-block position-absolute"
                  >
                    <div ngbDropdownMenu>
                      <ng-container *ngIf="promotions && promotions.length">
                        <ng-container
                          *ngFor="let groupedPromotions of promotions"
                        >
                          <button
                            class="px-3 py-1 dropdown-item btn"
                            *ngFor="let promotion of groupedPromotions"
                            [innerHTML]="
                              promotion.title === ''
                                ? promotion.promotioncode
                                : promotion.title
                            "
                            (click)="
                              gotoSearchPage(
                                'CMN_PROM&HOMEPROMO=' +
                                  promotion.promotioncode +
                                  '&COMPANY=' +
                                  +promotion.company_id,
                                $event
                              )
                            "
                          ></button>
                        </ng-container>
                      </ng-container>
                      <button
                        class="px-3 py-1 dropdown-item btn"
                        (click)="
                          gotoSearchPage(
                            'CMN_PROM&HOMEPROMO=' +
                              promotion.promotioncode +
                              '&COMPANY=' +
                              +promotion.company_id,
                            $event
                          )
                        "
                      ></button>
                      <ng-container
                        *ngIf="
                          promotions && !promotions.length && !promotionsLoaded
                        "
                      >
                        <button class="px-3 py-1 dropdown-item btn">
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </ng-container>
                      <button
                        class="px-3 py-1 dropdown-item btn fw-bold d-flex align-items-center"
                        *ngIf="promotionsLoaded"
                        (click)="gotoSearchPage('CMN_PROM' + (companyGroupCode === 'WG' ? '&groups=14&categories=Parts_&_Accessories' : ''), $event)"
                      >
                        <i class="material-icons">chevron_right</i>
                        {{ "VIEW_ALL" | translate }}
                      </button>
                    </div>
                  </div>
                </button>
              </button>

              <button
                *ngIf="
                  companyGroupsSettings &&
                  companyGroupsSettings.bom_companies &&
                  companyGroupsSettings.bom_companies.length
                "
                class="px-0 py-0 dropdown-item"
                (click)="gotoSearchPage('CMN_OLD,CMN_BOM', $event)"
              >
                <button class="btn generalItem">{{ "BOM" | translate }}</button>
              </button>
            </ng-container>
          </div>
        </div>
        <ng-container *ngFor="let item of generalItems">
          <div
            ngbDropdown
            class="d-inline-block dropdownContainer"
            [ngClass]="item.class"
            style="position: relative"
            *ngIf="item.sub_items"
            [ngClass]="[item.active ? 'show' : '']"
            #myDrop="ngbDropdown"
            (mouseenter)="openMenuItem(item)"
            (mouseleave)="dontopenMenuItem(item)"
            (click)="toggleMenuItem(item, $event)"
            (clickOutside)="item.active = false"
            (mouseleave)="item.active = false"
            [id]="'parent_' + item.name"
          >
            <button
              class="py-0 btn generalItem topItem dropdown-toggle ps-0"
              [id]="item.name"
              [innerHTML]="getDescription(item) | translate"
              [class.fw-bold]="item.name === 'promotions'"
              (mouseenter)="
                item.name === 'promotions' ? loadPromotions() : undefined
              "
              (click)="
                item.name === 'promotions'
                  ? gotoSearchPage('CMN_PROM', $event)
                  : undefined
              "
            ></button>

            <!-- Dropdown -->
            <div
              ngbDropdownMenu
              [ngClass]="[
                item.active ? 'show' : '',
                item.name === 'usersettings' ? 'userDropdown' : ''
              ]"
              class="mt-0"
            >
              <ng-container
                *ngIf="
                  promotions &&
                  !promotions.length &&
                  !promotionsLoaded &&
                  item.name === 'promotions'
                "
              >
                <button class="px-3 py-1 dropdown-item btn">
                  <span
                    *ngIf="
                      promotions && !promotions.length && !promotionsLoaded
                    "
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </ng-container>
              <ng-container
                *ngIf="
                  promotions && promotions.length && item.name === 'promotions'
                "
              >
                <ng-container *ngFor="let groupedPromotions of promotions">
                  <button
                    class="px-0 py-0 dropdown-item"
                    *ngFor="let promotion of groupedPromotions"
                  >
                    <button
                      class="py-2 btn generalItem"
                      [innerHTML]="
                        promotion.title === ''
                          ? promotion.promotioncode
                          : promotion.title
                      "
                      (click)="
                        gotoSearchPage(
                          'CMN_PROM&HOMEPROMO=' +
                            promotion.promotioncode +
                            '&COMPANY=' +
                            +promotion.company_id,
                          $event
                        );
                        item.active = false
                      "
                    ></button>
                  </button>
                </ng-container>
              </ng-container>
              <ng-container
                *ngIf="item.name === 'usersettings' && accounts?.length > 1"
              >
                <div class="p-2 d-block">
                  <span
                    class="btn btn-light"
                    (click)="selectLinkedAccountModal()"
                    >{{ "SELECT_ACCOUNT" | translate }}</span
                  >
                </div>
              </ng-container>
              <ng-container
              *ngIf="isAdmin && item.name === 'usersettings'"
            >
              <div class="p-2 d-block">
                <span
                  class="btn btn-light d-inline-flex align-items-center"
                  [routerLink]="'/' + companyGroupCode + '/admin/'"
                  >
                  <i class="material-icons me-1"> admin_panel_settings </i>
                  Admin tools</span
                >
              </div>
            </ng-container>
              <ng-container *ngFor="let subitem of item.sub_items">
                <button
                  class="px-0 py-0 dropdown-item"
                  [ngClass]="subitem.sub_items ? 'withSubmenu' : ''"
                >
                  <!-- Dropdown in dropdown-->
                  <button
                    class="py-2 btn generalItem dropdown-toggle"
                    [id]="subitem.name"
                    *ngIf="subitem.sub_items && showOption(subitem.name)"
                    on-mouseover="myDropSub ? myDropSub.open() : null"
                  >
                    <span
                      class="d-inline-block"
                      (click)="subitem.url ? gotoItem(subitem) : null"
                      style="width: 95%; min-width: 200px"
                      >{{ subitem.description | translate }}</span
                    >
                    <div
                      ngbDropdown
                      placement="right"
                      class="d-inline-block subDropdown"
                      style="position: relative"
                      *ngIf="subitem.sub_items"
                      #myDropSub="ngbDropdown"
                    >
                      <div ngbDropdownMenu *ngIf="subitem.sub_items">
                        <button
                          class="py-2 dropdown-item"
                          [ngClass]="[
                            subsubitem.sub_items ? 'withSubmenu' : ''
                          ]"
                          *ngFor="let subsubitem of subitem.sub_items"
                          (click)="
                            gotoItem(subsubitem);
                            item.active = false;
                            myDropSub.close()
                          "
                        >
                          <!-- Dropdown in dropdown-->
                          <button
                            class="p-0 btn generalItem dropdown-toggle last"
                            [id]="subsubitem.name"
                            *ngIf="
                              subsubitem.sub_items &&
                              showOption(subsubitem.name)
                            "
                            on-mouseover="
                              deepestDropdown ? deepestDropdown.open() : null
                            "
                          >
                            <span
                              class="d-inline-block"
                              (click)="
                                subsubitem.url ? gotoItem(subsubitem) : null
                              "
                              style="width: 95%; min-width: 200px"
                              >{{ subsubitem.description | translate }}</span
                            >
                            <div
                              ngbDropdown
                              placement="right"
                              class="deepestDropdown"
                              style="position: relative; display: none"
                              *ngIf="subsubitem.sub_items"
                              #deepestDropdown="ngbDropdown"
                            >
                              <div
                                ngbDropdownMenu
                                *ngIf="subsubitem.sub_items"
                                style="margin-left: 10px; margin-top: -20px"
                              >
                                <button
                                  *ngFor="
                                    let deepestItem of subsubitem.sub_items
                                  "
                                  class="py-2 dropdown-item"
                                  (click)="
                                    gotoItem(deepestItem); item.active = false
                                  "
                                >
                                  {{ deepestItem.description | translate }}
                                </button>
                              </div>
                            </div>
                          </button>

                          <!-- Normal item -->
                          <button
                            class="p-0 btn generalItem"
                            [id]="subsubitem.name"
                            *ngIf="
                              !subsubitem.sub_items &&
                              showOption(subsubitem.name)
                            "
                            (click)="gotoItem(subsubitem); item.active = false"
                          >
                            {{ subsubitem.description | translate }}
                          </button>
                        </button>
                      </div>
                    </div>
                  </button>

                  <!-- Normal item -->
                  <button
                    class="py-2 btn generalItem"
                    [id]="subitem.name"
                    *ngIf="!subitem.sub_items && showOption(subitem.name)"
                    (click)="gotoItem(subitem); item.active = false"
                  >
                    {{ subitem.description | translate }}
                  </button>
                </button>
              </ng-container>
              <ng-container *ngIf="item.name === 'usersettings'">
                <button class="px-0 py-0 dropdown-item">
                  <button
                    class="py-2 btn generalItem"
                    (click)="logout($event); item.active = false"
                  >
                    {{ "LOGOUT" | translate }}
                  </button>
                </button>
              </ng-container>
              <button
                class="py-2 text-end btn generalItem w-100 fw-bold d-sm-none d-inline-block"
                (click)="item.active = false"
              >
                {{ "CLOSE" | translate }}
              </button>
            </div>
          </div>
          <button
            class="py-0 btn generalItem topItem"
            [attr.id]="item.name"
            *ngIf="!item.sub_items"
            (click)="gotoItem(item)"
          >
            {{ item.description | translate }}
          </button>
        </ng-container>
        <button
          class="py-0 btn generalItem topItem"
          *ngIf="helpPageId[companyGroupCode]"
          [routerLink]="
            companyGroupCode +
            '/companyPage/' +
            this.helpPageId[companyGroupCode]
          "
        >
          {{ "CMS_SERVICE_AYUDA_ACCENTRY" | translate }}
        </button>

        <button
          class="py-0 btn generalItem topItem"
          *ngIf="promotionPageId[companyGroupCode]"
          [routerLink]="
            companyGroupCode +
            '/companyPage/' +
            this.promotionPageId[companyGroupCode]
          "
        >
          {{ "OFFERS" | translate }}
        </button>

        <button
          class="px-0 py-0 btn generalItem topItem px-sm-2"
          id="preorderButton"
          *ngIf="hasPreorder"
          (click)="gotoPreorder()"
          (mouseenter)="popoverPreorder.open()"
          placement="bottom"
          #popoverPreorder="ngbPopover"
          popoverClass="popoverPreorder"
          [disablePopover]="
            !showPreorderPopover ||
            (preorderBasket && !preorderBasket.items.length)
          "
          [ngbPopover]="popContent"
          [popoverTitle]="'PREORDER_BASKET' | translate"
        >
          {{ "PREORDER" | translate }}
        </button>
        <div
          ngbDropdown
          class="d-inline-block dropdownContainer"
          [ngClass]="showPromotionsDropdown ? 'show' : ''"
          (mouseenter)="getPromotions(); showPromotionsDropdown = true"
          (mouseleave)="showPromotionsDropdown = false"
          *ngIf="(companyGroupCode === 'RAL' || companyGroupCode === 'LAP') && showDealerItems"
        >
          <button
            type="button"
            class="py-0 btn generalItem topItem dropdown-toggle ps-0 ps-xl-1 fw-bold"
            id="dropdownPromotions"
            ngbDropdownToggle
          >
            {{ "PROMOTIONS" | translate }}
          </button>
          <div
            ngbDropdownMenu
            class="mt-0"
            [ngClass]="showPromotionsDropdown ? 'show' : ''"
            aria-labelledby="dropdownPromotions"
          >
            <ng-container
              *ngIf="promotions && !promotions.length && !promotionsLoaded"
            >
              <button class="px-3 py-1 dropdown-item btn">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </ng-container>
            <ng-container *ngFor="let groupedPromotions of promotions">
              <button
                class="px-3 py-1 dropdown-item btn"
                *ngFor="let promotion of groupedPromotions"
                [innerHTML]="
                  promotion.title === ''
                    ? promotion.promotioncode
                    : promotion.title
                "
                (click)="
                  gotoSearchPage(
                    'CMN_PROM&HOMEPROMO=' +
                      promotion.promotioncode +
                      '&COMPANY=' +
                      +promotion.company_id,
                    $event
                  );
                  showPromotionsDropdown = false
                "
              ></button>
            </ng-container>
            <div class="px-0 py-0 dropdown-item">
              <button
                class="px-3 py-1 generalItem btn fw-bold d-flex align-items-center"
                *ngIf="promotionsLoaded"
                (click)="
                  gotoSearchPage('CMN_PROM', $event);
                  showPromotionsDropdown = false
                "
              >
                <i class="material-icons">chevron_right</i>
                {{ "VIEW_ALL" | translate }}
              </button>
            </div>
          </div>
        </div>

        <button
          class="px-0 py-0 btn generalItem topItem search-menu-button px-sm-2 find pt-1"
          (click)="openSearch()"
          *ngIf="showDealerItems"
        >
          <i class="material-icons search-icon">search</i>
        </button>

        <ng-template #popContent>
          <span
            class="mb-2 justify-content-between d-flex"
            *ngIf="preorderBasket"
          >
            <span class="pt-2 me-2"
              >{{ "ARTICLES" | translate }} ({{ "BICYCLES" | translate }}):
              {{ preorderBasket.items.length }}</span
            >
            <button
              class="ms-1 btn btn-primary btn-sm"
              [routerLink]="
                applicationService.getSelectCompanyGroupCode() +
                '/preorderBasket/Bikes/' +
                preorderBasket.id
              "
            >
              {{ "VIEW" | translate }}
            </button>
          </span>
          <span
            class="justify-content-between d-flex"
            *ngIf="preorderBasketParts"
          >
            <span class="pt-2 me-2"
              >{{ "ARTICLES" | translate }} ({{ "PARTS" | translate }}):
              {{ preorderBasketParts.items.length }}</span
            >
            <button
              class="ms-1 btn btn-primary btn-sm"
              [routerLink]="
                applicationService.getSelectCompanyGroupCode() +
                '/preorderBasket/Parts/' +
                preorderBasketParts.id
              "
            >
              {{ "VIEW" | translate }}
            </button>
          </span>
        </ng-template>
      </div>
    </li>

    <li class="border-bottom ps-xxl-4">
      <app-catalogue-navbar
        [browseGroups]="browseGroups"
        (groupSelected)="groupSelectedFromNavbar($event)"
        [showBom]="
          companyGroupsSettings &&
          companyGroupsSettings.bom_companies &&
          companyGroupsSettings.bom_companies.length
        "
      ></app-catalogue-navbar>
    </li>

    <ng-container *ngIf="menuActive">
      <!-- Company groups -->
      <li
        class="pt-2 col-sm-3 col-md-3 col-lg-3 col-xl-2 companyGroupList"
        [@pageAnimations]
      >
        <ul class="companyGroups ani-item">
          <ng-container *ngFor="let company of availableCompanyGroups">
            <li
              (click)="setCompanyGroup($event, company.company_group_code)"
              [ngClass]="{
                active:
                  (companyGroupCode == 'WG' &&
                    company.company_group_code === 'WG') ||
                  (companyGroupCode === company.company_group_code &&
                    companyGroupCode !== 'WG')
              }"
              class="my-1 overflow-auto clickable withOpacity d-flex align-items-center"
            >
              <img
                [src]="
                  './assets/images/logos/' +
                  company.company_group_code.toLowerCase() +
                  '.png'
                "
              />
              {{ company.company_group_name.default }}
            </li>
          </ng-container>
          <!-- Add E.WBP as company group if applicable -->
          <ng-container *ngIf="hasEWBP">
            <li
              (click)="setCompanyGroup($event, 'WG')"
              [ngClass]="
                companyGroupCode === 'WG' &&
                applicationService.checkIfDealerHasCompany(101 && 102)
                  ? 'active'
                  : ''
              "
              class="my-1 overflow-auto clickable withOpacity"
            >
              <img [src]="'./assets/images/logos/e-wbp.png'" />
              E. Wiener Bike Parts
            </li>
          </ng-container>
        </ul>
      </li>

      <!-- Article groups -->
      <li
        class="col-sm-3 col-md-3 col-lg-2 scrollable ps-2 mt-0 customScrollbar groupContainer g-1"
        *ngIf="browseGroups && browseGroups.length"
        [@pageAnimations]
      >
        <h3 class="ani-item">{{ "CATALOG" | translate }}</h3>
        <ul class="list-group list-group-flush ani-item bg-transparent">
          <li
            class="list-group-item py-0 px-0 bg-transparent"
            *ngFor="let item of browseGroups"
          >
            <a
              style="margin-left: -0.25rem"
              class="ps-1 text-decoration-none"
              href="#"
              (click)="
                currentLeftItem =
                  currentLeftItem !== item.name
                    ? (currentLeftItem = item.name)
                    : '';
                selectArticleGroup($event, item)
              "
              [ngClass]="currentLeftItem === item.name ? 'active' : ''"
              >{{ item.description | translate }}</a
            >
          </li>
          <li
            *ngIf="
              showDealerItems &&
              companyGroupsSettings &&
              companyGroupsSettings.promotion_companies?.length
            "
            (click)="gotoSearchPage('CMN_PROM', $event)"
            class="list-group-item py-0 px-0 bg-transparent"
          >
            <a
              href="#"
              style="margin-left: -0.25rem"
              class="ps-1 text-decoration-none"
              (click)="gotoSearchPage('CMN_PROM', $event)"
            >
              {{ "PROMOTIONS" | translate }}
            </a>
          </li>
          <li
            *ngIf="
              companyGroupsSettings &&
              companyGroupsSettings.bom_companies &&
              companyGroupsSettings.bom_companies.length
            "
            class="list-group-item py-0 px-0 bg-transparent"
          >
            <a
              href="#"
              style="margin-left: -0.25rem"
              class="ps-1 text-decoration-none"
              (click)="gotoSearchPage('CMN_OLD,CMN_BOM', $event)"
            >
              {{ "BOM" | translate }}
            </a>
          </li>
        </ul>
      </li>

      <!-- Product groups -->
      <li
        class="col-sm-3 col-md-3 col-lg-2 scrollable customScrollbar groupContainer overlay g-2"
        *ngIf="currentLeftItem !== ''"
        [@enterAnimation]
      >
        <ul class="list-group list-group-flush">
          <li
            (click)="currentLeftItem = ''; back($event)"
            id="back1"
            class="my-2 btn btn-sm btn-primary d-block d-sm-none back list-group-item py-0 px-0 ani-item bg-transparent"
          >
            {{ "BACK" | translate }}
          </li>
          <li
            *ngFor="let item of selectedArticleGroups"
            class="list-group-item py-0 px-0 ani-item bg-transparent"
          >
            <a
              href="#"
              style="margin-left: -0.25rem"
              class="ps-1 text-decoration-none"
              [ngClass]="currentCenterItem === item.name ? 'active' : ''"
              (click)="
                currentCenterItem = item.name;
                selectProductGroup($event, item, false)
              "
              >{{ item.description | translate }}
            </a>
          </li>
          <li class="list-group-item py-0 ps-0 ani-item bg-transparent">
            <button
              *ngIf="selectedArticleGroups.length > 1"
              class="btn btn-block btn-dark mt-2 d-flex align-items-center"
              (click)="viewAllFromArticleGroup($event)"
            >
              <i class="material-icons">chevron_right</i>
              {{ "VIEW_ALL" | translate }}
            </button>
          </li>
        </ul>
      </li>

      <!-- Sub groups -->
      <li
        class="col-xs-12 col-sm-3 col-md-3 col-lg-5 scrollable customScrollbar groupContainer overlay g-3 bg-transparent"
        *ngIf="currentCenterItem !== ''"
      >
        <ul class="list-group list-group-flush">
          <li
            (click)="currentCenterItem = ''; back($event)"
            id="back2"
            class="my-2 btn btn-sm btn-primary d-block d-sm-none back list-group-item py-0 px-0 ani-item bg-transparent"
          >
            {{ "BACK" | translate }}
          </li>
          <li
            *ngFor="let item of selectedArticleSubGroups"
            class="list-group-item py-0 px-0 ani-item bg-transparent"
          >
            <a
              href="#"
              (click)="selectSubGroup($event, item)"
              class="text-decoration-none"
            >
              {{ item.description | translate }}
            </a>
          </li>
          <li class="list-group-item py-0 px-0 ani-item bg-transparent">
            <button
              class="btn btn-block btn-dark mt-2 d-flex align-items-center"
              (click)="viewAllFromArticleGroup($event)"
            >
              <i class="material-icons">chevron_right</i>
              {{ "VIEW_ALL" | translate }}
            </button>
          </li>
        </ul>
      </li>
      <li
        class="d-none d-sm-block col-sm-2 col-lg-1"
        *ngIf="currentLeftItem === ''"
      ></li>
      <!-- Quicklinks  -->
      <li
        class="col-xs-12 col-sm-4 col-lg-3 scrollable customScrollbar quickLinks"
        [style.display]="currentLeftItem === '' ? 'block' : 'none'"
        [@pageAnimations]
      >
        <h3 class="ani-item">{{ "QUICKLINKS" | translate }}</h3>
        <app-quick-links
          [homepageQuicklinks]="false"
          (linkClicked)="menuActive = false"
        >
        </app-quick-links>
      </li>
    </ng-container>
  </ul>
</div>
