import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ApplicationService } from "./application.service";
import { BasketArticle } from "../interfaces/basketArticle";
import { map } from 'rxjs/operators';
import { Subject, Subscription } from "rxjs";
import * as _ from "underscore";
import { GroupedBasketArticles } from "../interfaces/grouped-basket-articles";
import { BasketArticles } from "../interfaces/basket-articles";
import { ApiResponse } from "../interfaces/api-response";
import { Price } from "../interfaces/price";
import { BasketAction } from "../interfaces/basket-action";
import { AvailableArticleTypes } from "../interfaces/article-type";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Basket } from "../interfaces/basket";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { SetitemBasketArticle } from "../interfaces/setitems/setitem-basket-article";
import { Article } from "../interfaces/article";
import { GroupedBasketArticlesByMonth } from "../interfaces/grouped-basket-articles-by-month";
import { TrackingService } from "./tracking.service";
import { ArticleModalComponent } from "../components/modal-components/article-modal/article-modal.component";
import { Coupon } from "../interfaces/coupon";
import { BasketSumByBrand } from "../interfaces/basket/basket-sum-by-brand";
import { AlertService } from "./alert.service";
import { PreorderSettings } from "../interfaces/settings/preorder-settings";
import { PreorderService } from "./preorder.service";
import { BackorderArticle } from "../interfaces/backorder-article";
import { FinancialService } from "./financial.service";
import { HelperService } from "./helper.service";
import { AccountService } from "./account.service";
import { BasketType } from "../interfaces/basket-type";
@Injectable({
  providedIn: "root",
})
export class BasketService {
  private emitChangeBasketCount = new Subject<any>();
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeBasketCount.asObservable();
  allAllChangeEmitted$ = this.emitChangeSource.asObservable();
  modalRef = null;
  xhr: Subscription;
  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService,
    private ngbModal: NgbModal,
    private translate: TranslateService,
    private trackingService: TrackingService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private preorderService: PreorderService,
    private financialService: FinancialService,
    private helperService: HelperService,
    private accountService: AccountService
  ) {}

  // Service message commands
  emitChange(change: any) {
    this.emitChangeBasketCount.next(change);
  }

  // Service message commands
  emitAllAddedChange(change: any) {
    this.emitChangeSource.next(change);
  }

  public getActiveBasket(preorder = false) {
    const self = this;

    return new Promise((resolve, reject) => {
      this.xhr = self.http
        .get(
          environment.apiendpoint +
            (preorder ? "baskets/preorder" : "baskets/active")
        )
        .subscribe((response: ApiResponse) => {

          resolve(response);
        });
    });
  }

  cancelCall() {
    if (typeof this.xhr !== "undefined") {
      this.xhr.unsubscribe();
    }
  }

  public getActiveBasketIdAndCount() {
    const self = this;

    return new Promise((resolve, reject) => {
      self.http
        .get(`${environment.apiendpoint}baskets/active/minimal`)
        .subscribe((response: ApiResponse) => {

          resolve(response);
        });
    });
  }

  public updateBasket(basket: Basket) {
    return this.http.put(environment.apiendpoint + `baskets`, basket);
  }

  public getPreorderBasket(type: string) {
    return this.http.get(
      environment.apiendpoint +
        `baskets/preorder/${type === 'Parts' ? 'parts' : ''}?v=baskets`
    );
  }

  public deletePreorderModel(modelId: number, type: string) {
    return this.http.delete(
      `${environment.apiendpoint}baskets/preorder/models/${modelId}?v=baskets`
    );
  }

  public deletePreorderModelArticle(articleCode: string, type: string) {
    return this.http.delete(
      `${environment.apiendpoint}baskets/preorder/${type!=="Bicycles"?"":"Parts/"}articles/${articleCode}?v=baskets`
    );
  }

  public emptyBasket(basketId: number|string) {
    return this.http.delete(
      `${environment.apiendpoint}baskets/${basketId}/emptybasket?v=baskets`
    );
  }

  public getPreorderBasketModels(type: string) {

    const url = this.applicationService.isV2Basket() ? environment.apiendpoint +
    `baskets/preorder/${type}/items` :  environment.apiendpoint +
    `baskets/preorder/models/${type === "Parts" ? "parts" : ""}?v=baskets`

    return this.http.get(
      url
    );
  }

  public saveActiveBasketId(basketId) {
    localStorage.setItem("activeBasketId", basketId);
  }

  public getActiveBasketId() {
    // Check if there is a basket - else create
    let basketId = null;
    const promise = new Promise((resolve, reject) => {
      this.getActiveBasket().then((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.result) {
          const basket = apiResponse.result as Basket;

          // Only use unlocked baskets for adding articles
          if (!basket.locked && !basket.pre_order) {
            basketId = basket.id;
          }
        }

        if (!basketId) {
          this.createBasket(
            this.translate.instant("BASKET") +
              " " +
              moment().format("DD-MM-YYYY")
          ).subscribe((apiResponse: ApiResponse) => {
            if (apiResponse.success) {
              basketId = apiResponse.result["id"];
              resolve(basketId);
            } else {
              reject();
            }
          });
        } else {
          resolve(basketId);
        }
      });
    });
    return promise;
  }

  public showBasketArticle(article: Article, articleModalComponent, preorder = false) {
    this.closeBasketArticleModal();
    this.modalRef = this.ngbModal.open(articleModalComponent, {
      size: "lg",
      windowClass: article?.setitem || preorder ? "" : "sticky-right",
      backdrop: true,
      container: "#modalContainer",
    });
    this.modalRef.componentInstance.article = article;
    this.modalRef.componentInstance.modelId = article["id"];
    this.modalRef.componentInstance.article.img = article["images"];
    this.modalRef.componentInstance.showDetailed = false;
    this.modalRef.componentInstance.preOrder = preorder;
    this.modalRef.componentInstance.showInstant();
  }

  closeBasketArticleModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  parseDeliveryWeek(article: Article) {
    const parsedDeliveryWeek = String(
      article.stock_year +
        ((String(article.stock_week).length === 1 ? "0" : "") +
          String(article.stock_week))
    );
    return parsedDeliveryWeek;
  }

  public groupArticles(basketArticles) {
    const groupedArticles = new GroupedBasketArticles();
    groupedArticles.bicycles.checkoutCount = 0;
    groupedArticles.parts.checkoutCount = 0;

    for (let i = 0; i < basketArticles.length; i++) {
      const article = basketArticles[i];

      if (
        article.article_type === AvailableArticleTypes.F ||
        ((this.applicationService.getSelectCompanyGroupCode() === "GHO" ||
          this.applicationService.getSelectCompanyGroupCode() === "RAL") &&
          article.article_type === AvailableArticleTypes.FP1) ||
        article.parent_id
      ) {
        groupedArticles.bicycles.checkoutCount += article.checkout ? 1 : 0;
        groupedArticles.bicycles.items.push(article);
      } else {
        groupedArticles.parts.checkoutCount += article.checkout ? 1 : 0;
        groupedArticles.parts.items.push(article);
      }
    }

    for (let article of groupedArticles.bicycles.items) {
      if (article.parent_id) {
        const parentArticle = _.find(groupedArticles.bicycles.items, (i) => {
          return i.id === article.parent_id;
        });
        if (parentArticle) {
          parentArticle.children = [article];
        }
      }
    }

    return groupedArticles;
  }

  public groupArticlesByDirectDelivery(basketArticles) {
    const groupedArticles: BasketArticles = new BasketArticles();

    for (let i = 0; i < basketArticles.length; i++) {
      const article = basketArticles[i];
      if (
        (article.asap_delivery ||
          (article.item_details &&
            article.item_details.always_order &&
            article.delivery_week === 99)) &&
        !article.order_line_group
      ) {
        groupedArticles.items.push(article);
      }
    }

    return groupedArticles;
  }

  public groupArticlesByMonth(basketArticles) {
    const groupedArticles = new GroupedBasketArticlesByMonth();

    for (let i = 0; i < basketArticles.length; i++) {
      const article = basketArticles[i] as BasketArticle;

      if (!article.asap_delivery && !article.order_line_group) {
        const yearTemp = article.delivery_year;
        let monthTemp = this.applicationService.getMonthFromWeekDescription(
          article.delivery_week,
          article.delivery_year
        );

        if (Number(monthTemp) < this.preorderService.availableTimeSlots[0]) {
          const firstMonth = Number(monthTemp) + 1;
          if (firstMonth < 10) {
            monthTemp = `0${firstMonth}`;
          } else {
            monthTemp = String(firstMonth);
          }
        }

        const key = String(yearTemp) + monthTemp;
        if (!groupedArticles[key]) {
          groupedArticles[key] = new BasketArticles();
        }
        groupedArticles[key].items.push(article);
      } else if (article.order_line_group) {
        const group =
          article.order_line_group.indexOf("repair") >= 0 ? "repair" : "demo";

        if (!groupedArticles[group]) {
          groupedArticles[group] = new BasketArticles();
        }
        groupedArticles[group].items.push(article);
      }
    }

    return groupedArticles;
  }

  public addArticlesToBasket(articles: any[], basketId?: number|string) {
    const self = this;
    const basketArticles: BasketArticle[] = [];
    let totalQuantity = 0;
    return new Promise((resolve, reject) => {
      const promise = new Promise((resolve) => {
        if (basketId) {
          resolve(true);
        } else {
          this.getActiveBasketId().then((response) => {
            basketId = response as number;
            resolve(true);
          });
        }
      });

      promise.then(() => {
        for (let i = 0; i < articles.length; i++) {
          const article = articles[i];

          if (!article.delivery_week) {
            article.delivery_week = this.parseDeliveryWeek(article);
          }

          const basketArticle: BasketArticle = new BasketArticle();
          basketArticle.quantity = articles[i].quantity
            ? articles[i].quantity
            : articles[i].amount; // Todo
          basketArticle.remarks = articles[i].remarks
            ? articles[i].remarks
            : "";
          totalQuantity += basketArticle.quantity;
          // Remove dots
          if (String(basketArticle.quantity).indexOf(".") >= 0) {
            basketArticle.quantity = 1;
          }
          basketArticle.article_code = article.single_article_code;
          basketArticle.sold = article.sold ? article.sold : false;
          if (!article.asap_delivery) {
            basketArticle.week = article.delivery_week.substr(4);
            basketArticle.year = article.delivery_week.substr(0, 4);
          } else {
            basketArticle.week = null;
          }
          basketArticle.delivery_week = basketArticle.week;
          basketArticle.delivery_year = basketArticle.year;
          basketArticle.basket_id = basketId;
          basketArticle.asap_delivery = article.asap_delivery;
          basketArticle.backorder = article.back_order ? true : false;
          basketArticle.parent_basket_item_id = article.parent_basket_item_id;
          basketArticle.parent_id = article.parent_basket_item_id;
          basketArticle.warehouse = article.warehouse;
          basketArticle.thumbs = article.thumbs;
          if (article.type && article.type !== "Parts") {
            basketArticle.order_line_group = `${
              ["winora", "ghost", "haibike"].includes(article.brand)
                ? article.brand
                : ""
            }${article.type}`;
          }
          basketArticles.push(basketArticle);

          this.trackingService.addToBasket(article, basketArticle.quantity);
        }

        self.http
          .post(
            environment.apiendpoint + "baskets/" + basketId + "/items",
            basketArticles
          )
          .subscribe((response: any) => {
            if (response.success) {
              const basketAction = new BasketAction();
              basketAction.type = "addArticle";
              basketAction.articles = basketArticles;
              basketAction.basketId = basketId ? basketId : -1;
              basketAction.basketArticleId = 0;
              basketAction.quantity = totalQuantity;
              self.emitChange(basketAction);
              resolve(response);
            } else {
              resolve(response);
            }
          });
      });
    });
  }

  addSetItem(mainArticle, articles, basketId = 0, update = false) {
    const basketArticle: SetitemBasketArticle = new SetitemBasketArticle();
    basketArticle.article_code = mainArticle.article_code
      ? mainArticle.article_code
      : mainArticle.article_codes[0]; // todo check why difference
    basketArticle.basket_id = basketId;
    basketArticle.checkout = true;
    basketArticle.update = true;
    basketArticle.quantity = mainArticle.quantity;
    basketArticle.remarks = mainArticle.remarks;
    basketArticle.set_items = articles;
    basketArticle.sold = mainArticle.sold;
    basketArticle.delivery_year_week = mainArticle.delivery_year_week;
    if (update) {
      basketArticle.basket_item_id = mainArticle.basketArticleId;
    }

    this.trackingService.addToBasket(mainArticle, basketArticle.quantity);

    return new Promise((resolve, reject) => {
      const apiUrl =
        environment.apiendpoint + "baskets/" + basketId + "/setitems";
      const request = update
        ? this.http.put(apiUrl, basketArticle)
        : this.http.post(apiUrl, basketArticle);

      request.subscribe((apiResponse: ApiResponse) => {
        const basketAction = new BasketAction();
        basketAction.type = "addArticle";
        basketAction.article = null;
        basketAction.basketId = basketId;
        basketAction.basketArticleId = null;
        this.emitChange(basketAction);
        resolve(apiResponse);
      });
    });
  }

  calcTotal(
    articles,
    sender,
    showRoomMode,
    shippingCost: Price,
    type = "regular",
    total_coupon_discount: number = 0,
    overrideCheckout?: boolean
  ) {
    let sum = {};
    let convertedSum = {};
    const months = this.applicationService.getMonths();
    switch (type) {
      case "regular":
        sum = {
          total: 0,
          parts: 0,
          parts_available: 0,
          bicycles: 0,
          bicycles_available: 0,
        };
        convertedSum = {
          total: "",
          parts: "",
          parts_available: "",
          bicycles: "",
          bicycles_available: "",
        };
        break;
      case "preorder":
        sum = {
          total: 0,
        };
        convertedSum = {
          total: "",
        };
        for (const month of months) {
          sum[month] = 0;
          convertedSum[month] = 0;
        }
        break;
      case "direct":
        sum = {
          total: 0,
          direct: 0,
        };
        convertedSum = {
          total: "",
          direct: "",
        };
        break;
    }

    if (articles && articles.length) {
      for (let i = 0; i < articles.length; i++) {
        const article = articles[i];
        if (
          article.price &&
          article.item_details &&
          ((!overrideCheckout && article.checkout) || overrideCheckout)
        ) {
          const amount =
            article.amount *
            (!showRoomMode
              ? article.price.value
              : article.item_details.retail_price
              ? article.item_details.retail_price.value
              : 0);
          sum["total"] += amount;

          switch (type) {
            case "regular":
              if (
                article.item_details.article_type.startsWith("F") ||
                article.parent_id
              ) {
                sum["bicycles"] += amount;
                sum["bicycles_available"] += article.item_details.stock_status <= 2 ? amount : 0;
              } else {
                sum["parts"] += amount;
                sum["parts_available"] += article.item_details.stock_status <= 2 ? amount : 0;
              }
              break;
            case "preorder":
              if (article.delivery_year) {
                let key = '';
                const group = article.order_line_group
                  ? article.order_line_group.indexOf('repair') >= 0
                    ? 'repair'
                    : 'demo'
                  : '';
                let monthKey = '';

                if (group) {
                  key = group;
                } else if (!article.asap_delivery) {
                  monthKey = this.applicationService.getMonthFromWeek(
                    article.delivery_week,
                    article.delivery_year
                  );

                  if (Number(monthKey) < 10) {
                    monthKey = `0${monthKey}`;
                  }
                  let yearKey = moment()
                    .year(article.delivery_year)
                    .format('YYYY');
                  key = `${yearKey}${monthKey}`;
                }

                if (!sum['demo']) {
                  sum['demo'] = 0;
                }

                if (!sum['repair']) {
                  sum['repair'] = 0;
                }

                sum[key] += amount;
              }
              break;
            case "direct":
              if (article.asap_delivery) {
                sum["direct"] += amount;
              }
              break;
          }
        }
      }

      // Substract coupon amount
      sum["total"] -= total_coupon_discount;

      switch (type) {
        case "regular":
          convertedSum["bicycles"] = sum["bicycles"].toFixed(2);
          convertedSum["bicycles_available"] = sum["bicycles_available"].toFixed(2);
          convertedSum["parts"] = sum["parts"].toFixed(2);
          convertedSum["parts_available"] = sum["parts_available"].toFixed(2);
          break;
        case "preorder":
          for (const month of months) {
            convertedSum[month] = sum[month].toFixed(2);
          }
          if (sum["repair"]) {
            convertedSum["repair"] = sum["repair"].toFixed(2);
          }
          if (sum["demo"]) {
            convertedSum["demo"] = sum["demo"].toFixed(2);
          }
          break;
        case "direct":
          convertedSum["direct"] = sum["direct"].toFixed(2);
          break;
      }

      convertedSum["total"] = sum["total"].toFixed(2);

      if (shippingCost && shippingCost.value) {
        convertedSum["totalWithShipping"] = (
          sum["total"] + shippingCost.value
        ).toFixed(2);
      } else {
        convertedSum["totalWithShipping"] = sum["total"].toFixed(2);
      }

      this.emitChange({
        type: "totalChange",
        sender: sender,
        articles: articles,
      });
    }
    return convertedSum;
  }

  deleteArticle(basketId, basketArticle: BasketArticle) {
    if (basketArticle.item_details) {
      this.trackingService.removeFromBasket(
        basketArticle.item_details,
        basketArticle.amount
      );
    }

    return this.http.delete(
      environment.apiendpoint +
        "baskets/" +
        basketId +
        "/items/" +
        basketArticle.id
    );
  }

  removeFromBasket(basket, basketArticleId) {
    for (let i = 0; i < basket.items.length; i++) {
      const basketItem = basket.items[i];

      if (basketItem.id === basketArticleId) {
        basket.items.splice(i, 1);
      }
    }
  }

  updateArticle(basketId, article) {
    if (basketId) {
      return this.http.put(
        environment.apiendpoint + "baskets/" + basketId + "/items/",
        {
          language_code: this.applicationService.getSelectedLanguage(),
          company_group_code:
            this.applicationService.getSelectCompanyGroupCode(),
          basket_id: basketId,
          id: article.id,
          basket_item_id: article.id,
          remarks: article.remarks,
          quantity: article.amount,
          week: article.delivery_week,
          year: article.delivery_year,
          delivery_week: article.delivery_week,
          delivery_year: article.delivery_year,
          sold: article.sold,
          article_code: article.article_code,
          backorder: article.backorder,
          order_type: article.order_type,
          workshop: article.workshop,
        }
      );
    }
  }

  updateMultipleArticles(basketId, articles: BasketArticle[]) {
    if (basketId) {
      const filteredArticles = articles.map(article => ({
        article_code: article.article_code,
        quantity: article.quantity,
        delivery_week: article.delivery_week,
        delivery_year: article.delivery_year,
        id: article.id
      }));
      return this.http.put(`${environment.apiendpoint}baskets/${basketId}/items/multiple`,
        filteredArticles
      );
    }
  }

  updateArticleCheckoutStatus(basketId, articles, status) {
    return this.http.post(
      environment.apiendpoint + "baskets/" + basketId + "/items/checkout/",
      {
        language_code: this.applicationService.getSelectedLanguage(),
        company_group_code: this.applicationService.getSelectCompanyGroupCode(),
        basket_id: basketId,
        items: _.pluck(articles, "id"),
        checkout: status,
      }
    );
  }

  getBasket(basketId, type: BasketType = BasketType.Normal) {
    return this.http.get(`${environment.apiendpoint}baskets/${basketId}`);
  }
  getOrderedBasket(basketId) {
    return this.http.get(`${environment.apiendpoint}baskets/${basketId}/type/${BasketType.Order}`);
  }

  getCheckoutBasket(basketId) {
    return this.http.post(
      environment.apiendpoint + "baskets/" + basketId + "/checkout",
      {}
    );
  }

  orderBasket(
    basketId,
    selectedShippingIdBicycles = "",
    selectedShippingIdParts = ""
  ) {
    return this.http.post(
      environment.apiendpoint + "baskets/" + basketId + "/order",
      {
        Bicycles: selectedShippingIdBicycles
          ? selectedShippingIdBicycles
          : undefined,
        Parts: selectedShippingIdParts ? selectedShippingIdParts : undefined,
      }
    );
  }

  addPaymentConditionBasket(basketId, payConditions) {
    return this.http.post(
      environment.apiendpoint + "baskets/" + basketId + "/paymentcondition",
      payConditions
    );
  }

  // Create new basket
  createBasket(basketName) {
    return this.http.post(environment.apiendpoint + "baskets/", {
      name: basketName,
    });
  }

  // Delete the basket
  deleteBasket(basketId) {
    return this.http.delete(environment.apiendpoint + "baskets/" + basketId);
  }

  // Get baskets
  getBaskets() {
    return this.http.get(environment.apiendpoint + "baskets");
  }

  // Get the ordered baskets
  getOrderedBaskets() {
    return this.http.get(environment.apiendpoint + "baskets/ordered");
  }

  // Get a pdf from baskets
  getPdfFile(basketId) {
    return this.http.post(
      environment.apiendpoint + "baskets/pdf/" + basketId + "?fileType=pdf",
      []
    );
  }

  // Get a pdf from baskets
  getOfferFile(basketId) {
    return this.http.post(
      environment.apiendpoint + "baskets/offer/" + basketId + "?fileType=pdf",
      []
    );
  }

  // Get an excel from baskets
  getExcelFile(basketId) {
    return this.http.post(
      environment.apiendpoint + "baskets/excel/" + basketId + "?t=excel",
      []
    );
  }

  // Saves the coupon codes to the basket
  saveCoupons(basketId: number, coupons: Coupon[]) {

      const selectedCoupons = [];

      // Set quantity
      coupons.forEach((coupon) => {
        if (coupon.coupon_quantity_selected || coupon.coupon_quantity_selected === 0) {
          selectedCoupons.push({
            quantity: coupon.coupon_quantity_selected,
            coupon_quantity: coupon.coupon_quantity_selected,
            coupon_quantity_selected: coupon.coupon_quantity_selected,
            coupon_code: coupon.coupon_code,
            value: coupon.value
          })
        }
      })

      return this.http.post(
        environment.apiendpoint + "baskets/" + basketId + "/coupons",
        selectedCoupons
      );
  }

  // Get the shippingcosts
  getShippingCosts() {
    return this.http.get(environment.apiendpoint + "baskets/shippingcosts");
  }

  removePreorderItemAction() {
    const basketAction = new BasketAction();
    basketAction.type = "deletePreOrderArticle";
    basketAction.article = null;
    this.emitChange(basketAction);
  }

  basketHasBeenOrdered(basket: Basket) {
    if ((typeof basket?.order_number !== "undefined" && basket?.order_number !== "") || basket?.ordered) {
      return true;
    } else {
      return false;
    }
  }

  getBasketArticlesByBrand(
    basketArticles: BasketArticle[]
  ): BasketSumByBrand[] {
    const totals = {};
    const test = _.map(basketArticles, (b) => {
      if (
        b.item_details &&
        typeof totals[b.item_details.brand] === "undefined"
      ) {
        totals[b.item_details.brand] = {
          sum: 0,
          quantity: 0,
          currency: b.price?.currency,
          brand: b.item_details.brand,
        };
      }
      if (b.item_details) {
        totals[b.item_details.brand]["sum"] += b.price?.value * b.amount;
        totals[b.item_details.brand]["quantity"] += b.amount;
      }
    });

    return Object.values(totals);
  }

  unlockBasket(basketId) {
    this.applicationService.showLoader(true);
    return this.http
      .get(`${environment.apiendpoint}baskets/${basketId}/unlock`)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (apiResponse && apiResponse.success) {
          const basketAction = new BasketAction();
          basketAction.type = "unlocked";
          this.emitChange(basketAction);
          this.alertService.showInfoNotification(
            `${this.translateService.instant("BASKET_UNLOCKED")}`
          );
        } else {
          this.alertService.showErrorNotification(
            `${this.translateService.instant("BASKET_UNLOCK_ERROR")}`
          );
        }
      });
  }

  getKioskBasket(): Promise<number|string> {
    const currentId = sessionStorage.getItem("kiosk-basket-id");
    const key = `Kiosk ${moment().format("DD-MM-YYYY")}`;
    if (!currentId) {
      return this.createKioskBasket(key);
    }
    return new Promise((resolve) => {
      this.getBasket(currentId).subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.result) {
          resolve(Number(currentId));
        } else {
          return this.createKioskBasket(key).then((basketId: number) => {
            resolve(basketId);
          });
        }
        return false;
      });
    });
  }

  createKioskBasket(key: string): Promise<number|string> {
    return new Promise((resolve) => {
      this.createBasket(key).subscribe((apiResponseCreate: ApiResponse) => {
        this.getBaskets().subscribe((apiResponseAllBaskets: ApiResponse) => {
          if (apiResponseAllBaskets && apiResponseAllBaskets.result) {
            const baskets = apiResponseAllBaskets.result as Basket[];
            const createdBasket = baskets.find((b) => {
              return b.name === key;
            });
            sessionStorage.setItem("kiosk-basket-id", String(createdBasket.id));
            resolve(createdBasket.id);
          } else {
            resolve(0);
          }
        });
      });
    });
  }

  // Get the deleted baskets
  getDeletedBaskets() {
    return this.http.get(environment.apiendpoint + "baskets/archived");
  }

  // Get deleted basket
  getDeletedBasket(basketId) {
    return this.http.get(
      environment.apiendpoint + `baskets/archived/${basketId}`
    );
  }

  restoreBasket(basketId) {
    return this.http.get(
      `${environment.apiendpoint}baskets/${basketId}/restore`
    );
  }

  // Check if an article is already in backorder (ANL)
  getArticlesInBackorder(
    selectedArticles: Article[],
    companyGroupCode: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if ((companyGroupCode === "ANL" || companyGroupCode === "LAP") && !this.applicationService.isPartnerPortal()) {
        // call Api
        this.financialService
          .getArticlesInBackorder(_.pluck(selectedArticles, "single_article_code"))
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {

              // Check if article has more than 0 in back order
              const articles = apiResponse.result;
              const values = Object.values(articles) as BackorderArticle[];
              let articleInBackorder: Array<BackorderArticle> = [];
              values.forEach((v: BackorderArticle) => {
                if (v.quantity_in_backorder > 0) {
                  articleInBackorder.push(v);
                }
              });

              resolve(articleInBackorder);
            } else {
              resolve([]);
            }
          });
      } else {
        resolve([]);
      }
    });
  }
}
